<template>
  <v-form v-model="valid" class="campaign-form" ref="form" lazy-validation>
    <v-row>
      <v-col cols="6">
        <general-card class="pa-5 mt-5 three-quarter-height">
          <h6 class="mb-2">Agency Name</h6>
          <v-text-field
            label="Name of Agency"
            dense
            outlined
            required
            class="mb-4"
            v-model="agency_name"
            :rules="[(v) => !!v || 'Enter a name to proceed!']"
          ></v-text-field>

          <h6 class="mb-2">Contact Email</h6>
          <v-text-field
            outlined
            label="Contact Email"
            type="email"
            required
            dense
            class="mb-4"
            v-model="contact_email"
            :rules="[(v) => !!v || 'Enter a valid email to proceed!']"
          ></v-text-field>

          <h6 class="mb-2">Contact Phone Number</h6>
          <v-text-field
            outlined
            label="Contact Phone Number"
            required
            dense
            class="mb-4"
            v-model="contact_phone_number"
            :rules="[(v) => !!v || 'Enter a valid phone number to proceed!']"
          ></v-text-field>

          <h6 class="mb-2">Agency Logo</h6>

          <v-text-field
            outlined
            label="Logo_URL"
            required
            dense
            class="mb-4"
            v-model="logo_url"
            :rules="[(v) => !!v || 'Enter a valid logo url!']"
          ></v-text-field>

          <v-card-actions class="d-flex justify-end align-center mt-10">
            <v-btn color="primary" small @click="handleSubmit" class="mt-10">{{
              this.$route.name == "add-agency" ? "Add Agency" : "Update Agency"
            }}</v-btn>
          </v-card-actions>
        </general-card>
      </v-col>
    </v-row>
  </v-form>
</template>

<script>
import GeneralCard from "../../components/Layouts/Cards/GeneralCard.vue";

export default {
  components: {
    GeneralCard,
  },
  data: () => ({
    logo_url: "",
    valid: true,
    agency_name: "",
    contact_email: "",
    contact_phone_number: "",
    agency_id: "",
  }),
  props: {
    single_agency: {
      type: Object,
    },
  },

  methods: {
    async handleSubmit() {
      await this.validate();
      if (!this.valid) return;

      if (this.$route.name == "add-agency") {
        this.submit();
      } else {
        this.agency_update();
      }
    },
    async submit() {
      let body = {
        agency_name: this.agency_name,
        contact_email: this.contact_email,
        contact_phone_number: this.contact_phone_number,
        logo_url: this.logo_url,
      };
      this.$emit("create", body);
    },

    async agency_update() {
      let body = {
        agency_name: this.agency_name,
        contact_email: this.contact_email,
        contact_phone_number: this.contact_phone_number,
        logo_url: this.logo_url,
      };
      this.$emit("update", body);
    },
    async validate() {
      await this.$refs.form.validate();
    },
  },
  computed: {
    currentRoute() {
      return this.$route.name == "add-agency";
    },
  },
  watch: {
    single_agency() {
      if (this.$route.name === "update-agency" && this.single_agency) {
        this.agency_name = this.single_agency.agency_name
          ? this.single_agency.agency_name
          : null;
        this.contact_email = this.single_agency.contact_email
          ? this.single_agency.contact_email
          : null;
        this.contact_phone_number = this.single_agency.contact_phone_number
          ? this.single_agency.contact_phone_number
          : null;
        this.logo_url = this.single_agency.logo_url
          ? this.single_agency.logo_url
          : null;
      }
    },
  },
};
</script>

<style>
</style>
