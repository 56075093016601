<template>
  <div>
    <v-breadcrumbs :items="items"></v-breadcrumbs>
    <v-divider></v-divider>
    <add-new-agency
      @create="createAgency($event)"
      class="mt-5"
      :single_agency="single_agency"
      @update="update_Agency($event)"
    />
  </div>
</template>


<script>
import AddNewAgency from "../../../components/Users/AddNewAgency.vue";

export default {
  data: () => ({
    items: [
      {
        text: "Agencies",
        disabled: false,
        href: "#/users-management/agencies",
      },
      {
        text: "Add Agency/edit",
        disabled: true,
        href: "#",
      },
    ],
    error: null,
    id: null,
  }),
  components: {
    AddNewAgency,
  },

  computed: {
    single_agency() {
      return this.$store.state.single_agency;
    },
    agencies() {
      return this.$store.state.agencies;
    },
  },

  async mounted() {
    if (this.$route.name === "update-agency") {
      try {
        this.$store.commit("makingApiRequest", true);
        await this.$store.dispatch("readAgency", this.$route.params.id);
        this.$store.commit("makingApiRequest", false);
      } catch (error) {
        this.error = error;
        this.$store.commit("makingApiRequest", false);
      }
    }
  },
  methods: {
    async createAgency(body) {
      try {
        this.$store.commit("makingApiRequest", true);
        await this.$store.dispatch("addNewAgency", body);
        this.$store.commit("makingApiRequest", false);

        this.$notify({
          title: "Agency created successfully!",
          group: "success",
        });

        await this.$router.push("/users-management/agencies");
      } catch (error) {
        this.error = error;
        this.$store.commit("makingApiRequest", false);
      }
    },

    async update_Agency(body) {
      let combined_data = {
        id: this.$route.params.id,
        body: body,
      };
      try {
        this.$store.commit("makingApiRequest", true);
        await this.$store.dispatch("updateAgency", combined_data);
        this.$store.commit("makingApiRequest", false);

        this.$notify({
          title: "Agency updated successfully!",
          group: "success",
        });
        await this.$router.push({
          name: "all-agencies",
          params: this.$route.params.id,
        });
      } catch (error) {
        this.error = error;
        this.$store.commit("makingApiRequest", false);
      }
    },
  },

  watch: {
    agency() {
      this.agency_name = this.agency.agency_name;
      this.contact_email = this.agency.contact_email;
      this.contact_phone_number = this.agency.contact_phone_number;
      this.logo_url = this.agency.logo_url;
    },
  },
};
</script>

